import signInAction from "@actions/signInAction"
import getSubscriptionLoader from "@loaders/getSubscriptionLoader"
import ForgotPasswordPage from "@pages/account/ForgotPasswordPage"
import ResetPasswordPage from "@pages/account/ResetPasswordPage"
import SignInPage from "@pages/account/SignInPage"
import SubscriptionPage from "@pages/account/SubscriptionPage"
import { Navigate } from "react-router-dom"

const accountRoutes = [
  {
    index: true,
    element: <Navigate to="subscription" replace />,
  },
  {
    element: <ForgotPasswordPage />,
    path: "forgot-password",
  },
  {
    element: <ResetPasswordPage />,
    path: "reset-password",
  },
  {
    action: signInAction,
    element: <SignInPage />,
    path: "sign-in",
  },
  {
    element: <SubscriptionPage />,
    loader: getSubscriptionLoader,
    path: "subscription",
  },
]

export default accountRoutes
