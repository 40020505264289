import Layout from "@components/Layout"
import RadioInputButton from "@components/RadioInputButton"
import { useTranslation } from "react-i18next"
import { Form, useSubmit } from "react-router-dom"

export default function PersonalityPage() {
  const submit = useSubmit()
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.personality.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5 pb-8"
          method="post"
        >
          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.personality.confidence")}
            name="personality"
            onChange={handleChange}
            value="confidence"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.personality.creativity")}
            name="personality"
            onChange={handleChange}
            value="creativity"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.personality.adventure")}
            name="personality"
            onChange={handleChange}
            value="adventure"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.personality.sophistication")}
            name="personality"
            onChange={handleChange}
            value="sophistication"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.personality.playfulness")}
            name="personality"
            onChange={handleChange}
            value="playfulness"
          />
        </Form>
      </div>
    </Layout>
  )
}
