export async function hashValue(
  value: string | undefined,
): Promise<string | undefined> {
  if (!value) return

  const encoder = new TextEncoder()
  const data = encoder.encode(value)
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("")

  return hashHex
}
