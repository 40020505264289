import Layout from "@components/Layout"
import RadioInputButton from "@components/RadioInputButton"
import { useTranslation } from "react-i18next"
import { Form, useSubmit } from "react-router-dom"

export default function SharePhotoPage() {
  const submit = useSubmit()
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.sharePhoto.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5 pb-8"
          method="post"
        >
          <RadioInputButton
            className="justify-center py-8"

            label={t("pages.onboarding.sharePhoto.empowered")}
            name="sharePhoto"
            onChange={handleChange}
            value="empowered"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.sharePhoto.inspired")}
            name="sharePhoto"
            onChange={handleChange}
            value="inspired"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.sharePhoto.curious")}
            name="sharePhoto"
            onChange={handleChange}
            value="curious"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.sharePhoto.amused")}
            name="sharePhoto"
            onChange={handleChange}
            value="amused"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.sharePhoto.connected")}
            name="sharePhoto"
            onChange={handleChange}
            value="connected"
          />
        </Form>
      </div>
    </Layout>
  )
}
