import FacebookPixel from "@components/FacebookPixel"
import { GlobalContextProvider } from "@context/GlobalContext"
import initializeSherlockObserver from "@helpers/initializeSherlockObserver"
import ErrorPage from "@pages/ErrorPage"
import routes from "@pages/routes"
import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import "./i18n"
import "./index.css"

if (import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useEffect: useEffect,
        useLocation,
        useNavigationType,
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(routes)

export default function App() {
  useEffect(() => {
    if (import.meta.env.MODE === "development") return

    const sherlockObserver = initializeSherlockObserver()

    sherlockObserver.observe({ entryTypes: ["resource"] })

    return () => {
      sherlockObserver.disconnect()
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <GlobalContextProvider>
        <FacebookPixel
          pixelId={import.meta.env.VITE_FACEBOOK_PIXEL_ID as string}
        />

        <RouterProvider router={router} />
      </GlobalContextProvider>
    </Sentry.ErrorBoundary>
  )
}
