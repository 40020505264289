import { hashValue } from "@helpers/hashValue"
import { FACEBOOK_CLICK_ID_KEY } from "@helpers/localStorage"
import {
  getUserCity,
  getUserCountryCode,
  getUserEmail,
  getUserId,
  getUserZipCode,
} from "@helpers/sessionStorage"
import { trackEvent } from "@helpers/supabase"
import * as Sentry from "@sentry/react"
import Cookies from "js-cookie"
import { EventName } from "src/types"
import { v4 as uuidv4 } from "uuid"

export async function trackCompleteRegistrationEvent() {
  const eventId = createEventId()
  const eventName = "CompleteRegistration"

  await trackEventFromServer({ eventId, eventName })
  await trackEventFromClient({ eventId, eventName })
}

export async function trackInitiateCheckoutEvent({
  amountInCents,
  currency,
}: {
  amountInCents: number;
  currency: string;
}) {
  const sanitizedCurrency = sanitizeCurrency(currency)
  const sanitizedValue = sanitizeValue(amountInCents)

  const eventId = createEventId()
  const eventName = "InitiateCheckout"

  await trackEventFromServer({
    currency: sanitizedCurrency,
    eventId,
    eventName,
    value: sanitizedValue,
  })

  await trackEventFromClient({
    currency: sanitizedCurrency,
    eventId,
    eventName,
    value: sanitizedValue,
  })
}

export async function trackPurchaseEvent({
  amountInCents,
  currency,
}: {
  amountInCents: number;
  currency: string;
}) {
  const sanitizedCurrency = sanitizeCurrency(currency)
  const sanitizedValue = sanitizeValue(amountInCents)

  const eventId = createEventId()
  const eventName = "Purchase"

  await trackEventFromServer({
    currency: sanitizedCurrency,
    eventId,
    eventName,
    value: sanitizedValue,
  })

  await trackEventFromClient({
    currency: sanitizedCurrency,
    eventId,
    eventName,
    value: sanitizedValue,
  })
}

function createEventId(): string {
  return uuidv4()
}

function sanitizeCurrency(currency: string): string {
  return currency.toUpperCase()
}

function sanitizeValue(value: number): number {
  return value / 100
}

async function trackEventFromClient({
  currency,
  eventId,
  eventName,
  value,
}: {
  currency?: string;
  eventId: string;
  eventName: EventName;
  value?: number;
}) {
  // const userCity = await getUserCity();
  // const userCountryCode = (await getUserCountryCode()).toLowerCase();
  // const userEmail = await getUserEmail();
  // const userId = await getUserId();
  // const userZipCode = await getUserZipCode();

  const payload =
    eventName === "CompleteRegistration" ? {} : { currency, value }

  return new Promise<void>((resolve, reject) => {
    if (fbq) {
      // NOTE: Disable re-initialization of Facebook Pixel with advanced matching parameters.
      // TODO: Re-enable or remove.
      // fbq("init", import.meta.env.VITE_FACEBOOK_PIXEL_ID, {
      //   country: userCountryCode,
      //   ct: userCity,
      //   em: userEmail,
      //   external_id: userId,
      //   zp: userZipCode,
      // });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      fbq("track", eventName, payload, {
        eventID: eventId,
      })

      resolve()
    } else {
      Sentry.captureException("Facebook Pixel not loaded")
      reject(new Error("Facebook Pixel not loaded"))
    }
  })
}

async function trackEventFromServer({
  currency,
  eventId,
  eventName,
  value,
}: {
  currency?: string;
  eventId: string;
  eventName: EventName;
  value?: number;
}) {
  const eventSourceUrl = window.location.href
  const fbcFromCookie = Cookies.get("_fbc")
  const fbcFromLocalStorage = localStorage.getItem(FACEBOOK_CLICK_ID_KEY)
  const fbc = fbcFromCookie ?? fbcFromLocalStorage
  const fbp = Cookies.get("_fbp")
  const userCity = await getUserCity()
  const userCountryCode = (await getUserCountryCode()).toLowerCase()
  const userEmail = await getUserEmail()
  const userId = await getUserId()
  const userZipCode = await getUserZipCode()

  await trackEvent({
    currency,
    eventId,
    eventName,
    eventSourceUrl,
    fbc,
    fbcFromCookie,
    fbcFromLocalStorage,
    fbp,
    pixelId: import.meta.env.VITE_FACEBOOK_PIXEL_ID as string,
    userCity: await hashValue(userCity),
    userCountryCode: await hashValue(userCountryCode),
    userEmail: await hashValue(userEmail),
    userId: await hashValue(userId),
    userZipCode: await hashValue(userZipCode),
    value,
  })
}
