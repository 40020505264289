import AlreadySignedUpDialog from "@components/AlreadySignedUpDialog"
import Button from "@components/Button"
import Input from "@components/Input"
import Layout from "@components/Layout"
import { trackCompleteRegistrationEvent } from "@helpers/facebookTracking"
import {
  getPasswordFromSessionStorage,
  removePasswordFromSessionStorage,
} from "@helpers/sessionStorage"
import { signUp, storeGender } from "@helpers/supabase"
import { validateEmail } from "@helpers/validations"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

export default function EmailPage() {
  const [email, setEmail] = useState("")
  const [emailValid, setEmailValid] = useState(true)
  const [password, setPassword] = useState("")
  const [openAlreadySignedUpDialog, setOpenAlreadySignedUpDialog] =
    useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const isEmailValid = validateEmail(email)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) return
    event.preventDefault()

    if (isEmailValid) {
      setIsSubmitting(true)

      const { error } = await signUp({ email, password })

      if (error) {
        if (error.message === "User already registered") {
          setOpenAlreadySignedUpDialog(true)
        } else {
          console.error(error)
        }

        setIsSubmitting(false)

        return
      }

      await storeGender()

      removePasswordFromSessionStorage()

      await trackCompleteRegistrationEvent()

      setIsSubmitting(false)

      navigate("/sign-up/preparing")
    } else {
      setEmailValid(false)
    }
  }

  useEffect(() => {
    const passwordFromSessionStorage = getPasswordFromSessionStorage()

    if (passwordFromSessionStorage) {
      setPassword(passwordFromSessionStorage)
    } else {
      navigate("/sign-up/password")
    }
  }, [navigate])

  return (
    <>
      <Layout>
        <div className="flex h-full flex-col">
          <div className="grow space-y-6 text-center">
            <h1 className="heading">{t("pages.signUp.email.heading")}</h1>

            <p
              className={
                emailValid ? "theme-subheading" : "theme-subheading-error"
              }
            >
              {emailValid
                ? t("pages.signUp.email.subheading")
                : t("pages.signUp.email.emailNotValid")}
            </p>
          </div>

          <form
            className="flex grow flex-col justify-between"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="space-y-4">
              <Input
                autoFocus
                description={
                  <Trans
                    components={{
                      privacyPolicyLink: (
                        <Link
                          className="underline"
                          target="_blank"
                          to={"https://www.filterly.app/privacy"}
                        />
                      ),
                      termsAndConditionsLink: (
                        <Link
                          className="underline"
                          target="_blank"
                          to={"https://www.filterly.app/terms"}
                        />
                      ),
                    }}
                    i18nKey="pages.signUp.email.text"
                  />
                }
                invalid={!emailValid}
                onChange={(event) => setEmail(event.target.value)}
                placeholder={t("shared.email")}
                type="email"
              />
            </div>

            <Button
              disabled={email.length < 1 || isSubmitting}
              type="submit"
              title={t("shared.continue")}
            />
          </form>
        </div>
      </Layout>

      <AlreadySignedUpDialog
        open={openAlreadySignedUpDialog}
        onClose={() => setOpenAlreadySignedUpDialog(false)}
      />
    </>
  )
}
