import Button from "@components/Button"
import Dialog from "@components/Dialog"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AlreadySignedUpDialog({ open, onClose }: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    navigate("/account/sign-in")
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="space-y-14">
        <p className="text-2xl font-openSansBold text-center">
          {t("pages.signUp.email.alreadySignedUpDialog.text")}
        </p>

        <Button
          className="theme-button-primary-black"
          onClick={handleClick}
          title={t("pages.signUp.email.alreadySignedUpDialog.logIn")}
        />
      </div>
    </Dialog>
  )
}
