import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction"
import getOnboardingFlagLoader from "@loaders/getOnboardingFlagLoader"
import skipOnboardingLoader from "@loaders/skipOnboardingLoader"
import CompanyPage from "@pages/onboarding/CompanyPage"
import GenderPage from "@pages/onboarding/GenderPage"
import { Navigate, Outlet } from "react-router-dom"
import TryFirstPage from "@pages/onboarding/TryFirstPage"
import BestLookPage from "@pages/onboarding/BestLookPage"
import SharePhotoPage from "@pages/onboarding/SharePhotoPage"
import PersonalityPage from "@pages/onboarding/PersonalityPage"
import CurrentStylePage from "@pages/onboarding/CurrentStylePage"
import ComfortPage from "@pages/onboarding/ComfortPage"

const onboardingRoutes = [
  {
    path: "/onboarding",
    element: <Outlet />,
    loader: skipOnboardingLoader,
    children: [
      {
        element: <Navigate to="gender" replace />,
        index: true,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GenderPage />,
        loader: getOnboardingFlagLoader,
        path: "gender",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TryFirstPage />,
        loader: getOnboardingFlagLoader,
        path: "tryFirst",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <BestLookPage />,
        loader: getOnboardingFlagLoader,
        path: "bestLook",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CurrentStylePage />,
        loader: getOnboardingFlagLoader,
        path: "style",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <SharePhotoPage />,
        loader: getOnboardingFlagLoader,
        path: "sharePhoto",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ComfortPage />,
        loader: getOnboardingFlagLoader,
        path: "comfort",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <PersonalityPage />,
        loader: getOnboardingFlagLoader,
        path: "personality",
      },

      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
    ],
  },
]

export default onboardingRoutes
