import { Outlet } from "react-router-dom"
import getSubscriptionsLoader from "../../loaders/getSubscriptionsLoader"
import CancelationApplePage from "./CancelationApplePage"
import CancelationCompletedPage from "./CancelationCompletedPage"
import CancelationGooglePage from "./CancelationGooglePage"
import CancelationPage from "./CancelationPage"
import HelpCenterPage from "./HelpCenterPage"
import RefundApplePage from "./RefundApplePage"
import RefundCompletedPage from "./RefundCompletedPage"
import RefundGooglePage from "./RefundGooglePage"
import RefundPage from "./RefundPage"

const helpCenterRoutes = [
  {
    element: <HelpCenterPage />,
    index: true,
  },
  {
    element: <Outlet />,
    path: "cancelation",
    children: [
      {
        element: <CancelationPage />,
        index: true,
        loader: getSubscriptionsLoader,
      },
      {
        element: <CancelationApplePage />,
        path: "apple",
      },
      {
        element: <CancelationCompletedPage />,
        path: "completed",
      },
      {
        element: <CancelationGooglePage />,
        path: "google",
      },
    ],
  },
  {
    element: <Outlet />,
    path: "refund",
    children: [
      {
        element: <RefundPage />,
        index: true,
        loader: getSubscriptionsLoader,
      },
      {
        element: <RefundApplePage />,
        path: "apple",
      },
      {
        element: <RefundCompletedPage />,
        path: "completed",
      },
      {
        element: <RefundGooglePage />,
        path: "google",
      },
    ],
  },
]

export default helpCenterRoutes
