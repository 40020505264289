import Button from "@components/Button"
import ForgotPasswordDialog from "@components/ForgotPasswordDialog"
import Input from "@components/Input"
import Layout from "@components/Layout"
import { resetPasswordForEmail } from "@helpers/supabase"
import { validateEmail } from "@helpers/validations"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { t } = useTranslation()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    await resetPasswordForEmail({ email })

    setIsDialogOpen(true)
  }

  return (
    <>
      <Layout>
        <div className="flex h-full flex-col">
          <div className="grow space-y-6 text-center">
            <h1 className="heading">
              {t("pages.account.forgotPassword.heading")}
            </h1>

            <p className="theme-subheading">
              {t("pages.account.forgotPassword.enterEmail")}
            </p>
          </div>

          <form
            className="flex grow flex-col justify-between"
            onSubmit={handleSubmit}
          >
            <Input
              autoFocus
              placeholder={t("shared.email")}
              onChange={(event) => setEmail(event.target.value)}
              type="email"
            />

            <Button
              disabled={!validateEmail(email)}
              title={t("pages.account.forgotPassword.resetPassword")}
              type="submit"
            />
          </form>
        </div>
      </Layout>

      {isDialogOpen && (
        <ForgotPasswordDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  )
}
