import FrequentlyAskedQuestion from "@components/FrequentlyAskedQuestion"
import { useTranslation } from "react-i18next"

export default function FrequentlyAskedQuestions() {
  const { t } = useTranslation()

  return (
    <>
      <p className="heading mb-8">{t("components.faq.heading")}</p>

      <div className="space-y-6">
        <FrequentlyAskedQuestion
          text={t("components.faq.whyFilterlyText")}
          title={t("components.faq.whyFilterlyTitle")}
        />

        <FrequentlyAskedQuestion
          text={t("components.faq.howToGetAccessText")}
          title={t("components.faq.howToGetAccessTitle")}
        />

        <FrequentlyAskedQuestion
          text={t("components.faq.howToCancelText")}
          title={t("components.faq.howToCancelTitle")}
        />
      </div>
    </>
  )
}
