import isUserSignedInLoader from "@loaders/isUserSignedInLoader"
import EmailPage from "@pages/signUp/EmailPage"
import PasswordPage from "@pages/signUp/PasswordPage"
import { Navigate } from "react-router-dom"
import GettingReadyPage from "@pages/signUp/GettingReadyPage"

const signUpRoutes = [
  {
    index: true,
    element: <Navigate to="password" replace />,
  },
  {
    element: <EmailPage />,
    path: "email",
  },
  {
    element: <GettingReadyPage />,
    path: "preparing",
  },
  {
    element: <PasswordPage />,
    loader: isUserSignedInLoader,
    path: "password",
  },
]

export default signUpRoutes
