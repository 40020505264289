import getSubscriptionStatus from "@helpers/getSubscriptionStatus"
import { getSession } from "@helpers/supabase"
import { redirect } from "react-router-dom"

export default async function skipOnboardingLoader() {
  const { data } = await getSession()

  const userId = data?.session?.user?.id

  if (!userId) return null

  const subscriptionStatus = await getSubscriptionStatus(userId)

  if (subscriptionStatus === "active") {
    return redirect("/account/subscription")
  } else {
    return redirect("/checkout/plans")
  }
}
