export default function FilterlyLogoSvg() {
  return (
    <svg width="114" height="46" viewBox="0 0 114 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.44 36V15H12.39V18.6H5.55V23.85H11.49V27.33H5.55V36H1.44ZM14.1255 36V15H18.2355V36H14.1255ZM21.1275 36V15H25.2375V32.52H31.4775V36H21.1275ZM34.5077 18.6H29.9777V15H43.1177V18.6H38.5877V36H34.5077V18.6ZM44.858 36V15H55.628V18.6H48.968V23.61H54.908V27.09H48.968V32.52H55.748V36H44.858ZM58.0709 15H66.0509C67.3109 15.42 68.3809 16.2 69.2609 17.34C70.1609 18.46 70.6109 19.84 70.6109 21.48C70.6109 24.16 69.5509 26.13 67.4309 27.39L70.4609 36H66.2609L63.7109 28.35H62.1209V36H58.0709V15ZM62.1209 18.6V24.99H64.7009C65.0409 24.91 65.4109 24.57 65.8109 23.97C66.2309 23.35 66.4409 22.63 66.4409 21.81C66.4409 20.99 66.2609 20.27 65.9009 19.65C65.5609 19.03 65.2209 18.68 64.8809 18.6H62.1209ZM72.7779 36V15H76.8879V32.52H83.1279V36H72.7779ZM90.1481 36H85.7681V29.01L81.3281 15H85.4081L87.2681 21.39L87.8981 25.08H88.0181L88.6481 21.39L90.5081 15H94.5881L90.1481 29.01V36Z" fill="#EFF926"/>
      <path d="M103.81 2.88299C103.923 2.58458 104.373 2.58458 104.486 2.88299C104.965 4.14518 105.885 6.34018 106.805 7.26034C107.728 8.18293 109.812 9.10552 111 9.58309C111.29 9.69972 111.29 10.1348 111 10.2514C109.812 10.729 107.728 11.6516 106.805 12.574C105.885 13.4942 104.965 15.6893 104.486 16.9514C104.373 17.2498 103.923 17.2498 103.81 16.9514C103.332 15.6893 102.411 13.4942 101.491 12.574C100.571 11.6538 98.376 10.7338 97.1138 10.2551C96.8154 10.142 96.8154 9.69268 97.1138 9.5795C98.376 9.10074 100.571 8.18054 101.491 7.26034C102.411 6.34018 103.332 4.14518 103.81 2.88299Z" fill="url(#paint0_linear_463_1656)"/>
      <path d="M110.438 0.221053C110.56 -0.0736843 111.021 -0.0736843 111.143 0.221053C111.397 0.833442 111.758 1.58599 112.119 1.94673C112.482 2.30968 113.204 2.67264 113.785 2.92723C114.072 3.0527 114.072 3.49767 113.785 3.62313C113.204 3.87769 112.482 4.24063 112.119 4.6036C111.758 4.96432 111.397 5.71687 111.143 6.32926C111.021 6.62399 110.56 6.62399 110.438 6.32926C110.183 5.71687 109.823 4.96432 109.462 4.6036C109.101 4.24286 108.349 3.88214 107.736 3.62782C107.442 3.50542 107.442 3.04496 107.736 2.92253C108.349 2.66819 109.101 2.30745 109.462 1.94673C109.823 1.58599 110.183 0.833442 110.438 0.221053Z" fill="url(#paint1_linear_463_1656)"/>
      <path d="M97.5808 1.98643C97.7145 1.69658 98.1835 1.69658 98.3171 1.98643C98.4688 2.31549 98.6517 2.64948 98.8346 2.83238C99.0199 3.01772 99.346 3.20305 99.6624 3.35593C99.9439 3.49189 99.9439 3.94418 99.6624 4.08013C99.346 4.23299 99.0199 4.41832 98.8346 4.60365C98.6517 4.78654 98.4688 5.12054 98.3171 5.4496C98.1835 5.73945 97.7145 5.73945 97.5808 5.4496C97.4291 5.12054 97.2462 4.78654 97.0633 4.60365C96.8804 4.42075 96.5465 4.23785 96.2174 4.08616C95.9275 3.95255 95.9275 3.48351 96.2174 3.3499C96.5464 3.19819 96.8804 3.01528 97.0633 2.83238C97.2462 2.64948 97.4291 2.31549 97.5808 1.98643Z" fill="url(#paint2_linear_463_1656)"/>
      <path d="M101.81 2.88299C101.923 2.58458 102.373 2.58458 102.486 2.88299C102.965 4.14518 103.885 6.34018 104.805 7.26034C105.728 8.18293 107.812 9.10552 109 9.58309C109.29 9.69972 109.29 10.1348 109 10.2514C107.812 10.729 105.728 11.6516 104.805 12.574C103.885 13.4942 102.965 15.6893 102.486 16.9514C102.373 17.2498 101.923 17.2498 101.81 16.9514C101.332 15.6893 100.411 13.4942 99.4913 12.574C98.5711 11.6538 96.376 10.7338 95.1138 10.2551C94.8154 10.142 94.8154 9.69268 95.1138 9.5795C96.376 9.10074 98.5711 8.18054 99.4913 7.26034C100.411 6.34018 101.332 4.14518 101.81 2.88299Z" fill="#EFF926"/>
      <path d="M108.438 0.221053C108.56 -0.0736843 109.021 -0.0736843 109.143 0.221053C109.397 0.833442 109.758 1.58599 110.119 1.94673C110.482 2.30968 111.204 2.67264 111.785 2.92723C112.072 3.0527 112.072 3.49767 111.785 3.62313C111.204 3.87769 110.482 4.24063 110.119 4.6036C109.758 4.96432 109.397 5.71687 109.143 6.32926C109.021 6.62399 108.56 6.62399 108.438 6.32926C108.183 5.71687 107.823 4.96432 107.462 4.6036C107.101 4.24286 106.349 3.88214 105.736 3.62782C105.442 3.50542 105.442 3.04496 105.736 2.92253C106.349 2.66819 107.101 2.30745 107.462 1.94673C107.823 1.58599 108.183 0.833442 108.438 0.221053Z" fill="#EFF926"/>
      <path d="M95.5808 1.98643C95.7145 1.69658 96.1835 1.69658 96.3171 1.98643C96.4688 2.31549 96.6517 2.64948 96.8346 2.83238C97.0199 3.01772 97.346 3.20305 97.6624 3.35593C97.9439 3.49189 97.9439 3.94418 97.6624 4.08013C97.346 4.23299 97.0199 4.41832 96.8346 4.60365C96.6517 4.78654 96.4688 5.12054 96.3171 5.4496C96.1835 5.73945 95.7145 5.73945 95.5808 5.4496C95.4291 5.12054 95.2462 4.78654 95.0633 4.60365C94.8804 4.42075 94.5465 4.23785 94.2174 4.08616C93.9275 3.95255 93.9275 3.48351 94.2174 3.3499C94.5464 3.19819 94.8804 3.01528 95.0633 2.83238C95.2462 2.64948 95.4291 2.31549 95.5808 1.98643Z" fill="#EFF926"/>
      <defs>
        <linearGradient id="paint0_linear_463_1656" x1="96.89" y1="5.48174" x2="96.89" y2="15.3607" gradientUnits="userSpaceOnUse">
          <stop stopColor="#810AF6" stopOpacity="0.9"/>
          <stop offset="0.447308" stopColor="#F7006F" stopOpacity="0.9"/>
          <stop offset="1" stopColor="#F5C003"/>
        </linearGradient>
        <linearGradient id="paint1_linear_463_1656" x1="107.515" y1="1.27367" x2="107.515" y2="5.73152" gradientUnits="userSpaceOnUse">
          <stop stopColor="#810AF6" stopOpacity="0.9"/>
          <stop offset="0.447308" stopColor="#F7006F" stopOpacity="0.9"/>
          <stop offset="1" stopColor="#F5C003"/>
        </linearGradient>
        <linearGradient id="paint2_linear_463_1656" x1="96" y1="2.52698" x2="96" y2="5.17974" gradientUnits="userSpaceOnUse">
          <stop stopColor="#810AF6" stopOpacity="0.9"/>
          <stop offset="0.447308" stopColor="#F7006F" stopOpacity="0.9"/>
          <stop offset="1" stopColor="#F5C003"/>
        </linearGradient>
      </defs>
    </svg>

  )
}
