import i18next from "i18next"
import appStoreBadgeCs from "../assets/app_store_badges/app_store_badge_cs.svg"
import appStoreBadgeDa from "../assets/app_store_badges/app_store_badge_da.svg"
import appStoreBadgeDe from "../assets/app_store_badges/app_store_badge_de.svg"
import appStoreBadgeEl from "../assets/app_store_badges/app_store_badge_el.svg"
import appStoreBadgeEn from "../assets/app_store_badges/app_store_badge_en.svg"
import appStoreBadgeEs from "../assets/app_store_badges/app_store_badge_es.svg"
import appStoreBadgeFi from "../assets/app_store_badges/app_store_badge_fi.svg"
import appStoreBadgeFr from "../assets/app_store_badges/app_store_badge_fr.svg"
import appStoreBadgeHi from "../assets/app_store_badges/app_store_badge_hi.svg"
import appStoreBadgeHr from "../assets/app_store_badges/app_store_badge_hr.svg"
import appStoreBadgeHu from "../assets/app_store_badges/app_store_badge_hu.svg"
import appStoreBadgeIt from "../assets/app_store_badges/app_store_badge_it.svg"
import appStoreBadgeNl from "../assets/app_store_badges/app_store_badge_nl.svg"
import appStoreBadgeNo from "../assets/app_store_badges/app_store_badge_no.svg"
import appStoreBadgePl from "../assets/app_store_badges/app_store_badge_pl.svg"
import appStoreBadgePt from "../assets/app_store_badges/app_store_badge_pt.svg"
import appStoreBadgeRo from "../assets/app_store_badges/app_store_badge_ro.svg"
import appStoreBadgeRu from "../assets/app_store_badges/app_store_badge_ru.svg"
import appStoreBadgeSv from "../assets/app_store_badges/app_store_badge_sv.svg"
import appStoreBadgeZh from "../assets/app_store_badges/app_store_badge_zh.svg"

// NOTE: Download the official badges here: https://developer.apple.com/app-store/marketing/guidelines/

const appStoreBadges: Record<string, string> = {
  cs: appStoreBadgeCs,
  da: appStoreBadgeDa,
  de: appStoreBadgeDe,
  el: appStoreBadgeEl,
  en: appStoreBadgeEn,
  es: appStoreBadgeEs,
  fi: appStoreBadgeFi,
  fr: appStoreBadgeFr,
  hi: appStoreBadgeHi,
  hr: appStoreBadgeHr,
  hu: appStoreBadgeHu,
  it: appStoreBadgeIt,
  nl: appStoreBadgeNl,
  no: appStoreBadgeNo,
  pl: appStoreBadgePl,
  pt: appStoreBadgePt,
  ro: appStoreBadgeRo,
  ru: appStoreBadgeRu,
  sv: appStoreBadgeSv,
  zh: appStoreBadgeZh,
}

export default function getAppStoreBadge(): string {
  const language = i18next.language.split("-")[0]

  return appStoreBadges[language] || appStoreBadgeEn
}
