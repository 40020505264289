import { PostHogProvider } from "posthog-js/react"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import "./i18n"
import "./index.css"

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_API_KEY as string}
      options={{ api_host: import.meta.env.VITE_POSTHOG_API_HOST as string }}
    >
      <App />
    </PostHogProvider>
  </StrictMode>,
)
