import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Layout from "../../components/Layout"
import RadioInputButton from "@components/RadioInputButton"

export default function HelpCenterPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Layout>
      <div className="shrink-0">
        <h1 className="mb-11 font-openSansBold">{t("pages.helpCenter.heading")}</h1>
      </div>

      <div className="flex grow flex-col justify-center space-y-5">
        <RadioInputButton
          className="justify-center py-8"
          label={t("pages.helpCenter.generalQuestionOrSuggestion")}
          name="style"
          onChange={() => window.location.href = "mailto:support@filterly.app"}
          value={t("pages.helpCenter.generalQuestionOrSuggestion")}
        />

        <RadioInputButton
          className="justify-center py-8"
          label={t("pages.helpCenter.cancelSubscription")}
          name="style"
          onChange={() => navigate("/help-center/cancelation")}
          value={t("pages.helpCenter.cancelSubscription")}
        />

        <RadioInputButton
          className="justify-center py-8"
          label={t("pages.helpCenter.requestRefund")}
          name="style"
          onChange={() => navigate("/help-center/refund")}
          value={t("pages.helpCenter.requestRefund")}
        />
      </div>
    </Layout>
  )
}
