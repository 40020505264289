import { trackWithSherlock } from "@helpers/backend"

export default function initializeSherlockObserver() {
  const sherlockObserver = new PerformanceObserver(
    (list: PerformanceObserverEntryList) => {
      for (const entry of list.getEntries()) {
        if (entry.name.includes("https://www.facebook.com/tr/")) {
          const params: Record<string, string> = {}
          const urlParams = new URLSearchParams(entry.name.split("?")[1])

          for (const [key, value] of urlParams.entries()) {
            params[key] = value
          }

          if (
            [
              "CompleteRegistration",
              "InitiateCheckout",
              "PageView",
              "Purchase",
            ].includes(params.ev)
          ) {
            void trackWithSherlock({ params })
          }
        }
      }
    },
  )

  return sherlockObserver
}
