import Layout from "@components/Layout"
import Spinner from "@components/Spinner"

export default function LoadingPage() {
  return (
    <Layout>
      <div className="flex h-full flex-col items-center justify-center">
        <Spinner />
      </div>
    </Layout>
  )
}
