export const FACEBOOK_CLICK_ID_KEY = "fbc"

const GENDER_KEY = "gender"

export function addFacebookParamsToLocalStorage(locationSearch: string) {
  const urlSearchParams = new URLSearchParams(locationSearch)

  addFbcToLocalStorage(urlSearchParams)
  addOtherFacebookParamsToLocalStorage(urlSearchParams)
}

function addFbcToLocalStorage(urlSearchParams: URLSearchParams) {
  const fbclid = urlSearchParams.get("fbclid")

  if (fbclid) {
    const creationTime = Date.now()
    const subdomainIndex = import.meta.env.MODE === "development" ? 0 : 1

    // NOTE: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/#2--format-clickid
    const fbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`

    localStorage.setItem(FACEBOOK_CLICK_ID_KEY, fbc)
  }
}

function addOtherFacebookParamsToLocalStorage(
  urlSearchParams: URLSearchParams,
) {
  const adParams = ["vm_c", "vm_co", "vm_m", "vm_s", "vm_t"]

  adParams.forEach((param) => {
    const value = urlSearchParams.get(param)

    if (value) localStorage.setItem(param, value)
  })
}

export function addGenderToLocalStorage(isFemale: boolean) {
  const gender = isFemale ? "female" : "male"
  localStorage.setItem(GENDER_KEY, gender)
}

export function getIsFemaleFromLocalStorage() {
  return localStorage.getItem(GENDER_KEY) === "female"
}
