import { CheckCircleIcon } from "@heroicons/react/24/solid"

interface Props {
  text: string;
  title: string;
}

export default function BulletPoint({ text, title }: Props) {
  return (
    <div className="flex items-start gap-4">
      <CheckCircleIcon className="theme-bullet-point h-8 w-8 shrink-0" />

      <div>
        <p className="font-openSansBold">{title}</p>

        <p>{text}</p>
      </div>
    </div>
  )
}
