export default function RadioInputButton({
  className,
  emoji,
  image,
  label,
  name,
  onChange,
  text,
  value,
}: {
  className?: string;
  emoji?: string;
  image?: string;
  label?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text?: string;
  value: string;
}) {
  const emojiSize = label ? "text-normal" : "text-3xl"

  return (
    <label className={`${className} theme-button-input flex items-center gap-4`}>
      <input
        className="hidden"
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />

      {image && <img className={"w-20 mr-1"} src={image}/>}

      {emoji && <span className={`${emojiSize} mr-1`}>{emoji}</span>}

      {label}

      {text && (
        <>
          <br />
          <span className="text-xs">{text}</span>
        </>
      )}
    </label>
  )
}
