import { Button as HeadlessUiButton } from "@headlessui/react"

interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  title: string;
  type?: "button" | "submit" | "reset";
}

export default function Button({
  className = "",
  disabled = false,
  onClick,
  title,
  type = "button",
}: Props) {
  return (
    <HeadlessUiButton
      className={`${className} theme-button-primary`}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {title}
    </HeadlessUiButton>
  )
}
