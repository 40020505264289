import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { RefundType } from "@pages/helpCenter/RefundPage"
import { Trans, useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { RefundRevenueCatSubscriptionsData } from "src/types"
import Layout from "../../components/Layout"

type RefundCompletedPageLocationState = RefundRevenueCatSubscriptionsData & {
  refundType: RefundType;
};

export default function RefundCompletedPage() {
  const { t } = useTranslation()
  const location = useLocation()

  const {
    activeAppStoreSubscriptionsCount,
    activePlayStoreSubscriptionsCount,
    canceledStripeSubscriptionsCount,
    refundedStripeSubscriptionsCount,
    refundType,
  } = (location.state as RefundCompletedPageLocationState) || {}

  const appleUrl = "https://support.apple.com/118223"

  const googleUrl = "https://support.google.com/googleplay/workflow/9813244"

  const hasAppleSubscription = activeAppStoreSubscriptionsCount > 0

  const hasGoogleSubscription = activePlayStoreSubscriptionsCount > 0

  const hasAppleAndGoogleSubscriptions =
    hasAppleSubscription && hasGoogleSubscription

  const hasAppleOrGoogleSubscription =
    hasAppleSubscription || hasGoogleSubscription

  const hasCanceledWebSubscription = canceledStripeSubscriptionsCount > 0

  const hasRefundedWebSubscription = refundedStripeSubscriptionsCount > 0

  const followGuidelinesTranslation = () => {
    if (hasAppleAndGoogleSubscriptions)
      return t(
        "pages.helpCenter.refundCompleted.followGoogleAndAppleGuidelines",
      )

    if (hasAppleSubscription)
      return t("pages.helpCenter.refundCompleted.followAppleGuidelines")

    return t("pages.helpCenter.refundCompleted.followGoogleGuidelines")
  }

  const regardingSubscriptionTranslation = () => {
    if (hasAppleAndGoogleSubscriptions)
      return t("pages.helpCenter.regardingGoogleAndAppleSubscriptions")

    if (hasGoogleSubscription)
      return t("pages.helpCenter.regardingGoogleSubscription")

    return t("pages.helpCenter.regardingAppleSubscription")
  }

  if (hasCanceledWebSubscription && !hasRefundedWebSubscription)
    return (
      <Layout>
        <div className="flex h-full flex-col items-center justify-center space-y-8 text-center">
          <ExclamationTriangleIcon className="h-20 text-help-center-warning" />

          <p className="text-2xl font-openSansBold">
            {t("pages.helpCenter.weAreSorry")}
          </p>

          <p className="font-openSans">
            <Trans
              components={{
                boldError: (
                  <span className="font-openSansBold text-help-center-error" />
                ),
                boldSuccess: (
                  <span className="font-openSansBold text-help-center-success" />
                ),
              }}
              i18nKey="pages.helpCenter.refundCompleted.subscriptionCouldNotBeRefunded"
            />
          </p>
        </div>
      </Layout>
    )

  if (!hasAppleOrGoogleSubscription || refundType === "web") {
    return (
      <Layout>
        <div className="flex h-full flex-col items-center justify-center space-y-8 text-center">
          <CheckCircleIcon className="h-20 text-help-center-success" />

          <p className="text-2xl font-openSansBold">
            {t("pages.helpCenter.thankYou")}
          </p>

          <p className="font-openSans">
            <Trans
              components={{
                bold: <span className="font-openSansBold text-help-center-success" />,
              }}
              i18nKey="pages.helpCenter.refundCompleted.subscriptionSuccessfullyCanceledAndRefunded"
            />
          </p>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="flex flex-col items-center justify-center space-y-8 text-center">
        <p className="text-2xl font-openSansBold">
          {t("pages.helpCenter.shared.furtherActionNeeded")}
        </p>

        {hasRefundedWebSubscription && (
          <p>
            <Trans
              components={{
                bold: <span className="font-openSansBold text-help-center-success" />,
              }}
              i18nKey="pages.helpCenter.refundCompleted.webSubscriptionCanceledAndRefunded"
            />
          </p>
        )}

        {hasAppleOrGoogleSubscription && refundType === "all" && (
          <>
            <p className="font-openSansBold">{regardingSubscriptionTranslation()}</p>

            <p className="font-openSans">
              {followGuidelinesTranslation()}{" "}
              <span className="font-openSansBold">
                {t(
                  "pages.helpCenter.refundCompleted.followInstructionsToRefund",
                )}
              </span>
            </p>
          </>
        )}

        {hasGoogleSubscription && refundType === "all" && (
          <div className="space-y-2">
            <p className="font-openSansBold">Google:</p>

            <div className="w-full rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center sm:w-auto">
              <Link
                className="break-all underline"
                target="_blank"
                to={googleUrl}
              >
                {googleUrl}
              </Link>
            </div>
          </div>
        )}

        {hasAppleSubscription && refundType === "all" && (
          <div className="space-y-2">
            <p className="font-openSansBold">Apple:</p>

            <div className="w-full rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center sm:w-auto">
              <Link
                className="break-all underline"
                target="_blank"
                to={appleUrl}
              >
                {appleUrl}
              </Link>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
