import { getOrCreateRevenueCatCustomer } from "@helpers/getOrCreateRevenueCatCustomer"
import { getUser } from "@helpers/supabase"
import { RevenueCatCustomer, RevenueCatSubscription } from "../types"

export interface GetSubscriptionLoaderData {
  revenueCatSubscription: RevenueCatSubscription | undefined;
  userEmail: string | undefined;
}

export default async function getSubscriptionLoader(): Promise<GetSubscriptionLoaderData> {
  const { userId, userEmail } = await getSupabaseUser()

  if (!userId)
    return { revenueCatSubscription: undefined, userEmail: undefined }

  const revenueCatCustomer: RevenueCatCustomer =
    await getOrCreateRevenueCatCustomer(userId)

  const revenueCatSubscriptionId =
    revenueCatCustomer?.subscriber.entitlements?.premium?.product_identifier

  const revenueCatSubscription =
    revenueCatCustomer?.subscriber.subscriptions[revenueCatSubscriptionId]

  return {
    revenueCatSubscription,
    userEmail,
  }
}

async function getSupabaseUser(): Promise<{
  userEmail: string | undefined;
  userId: string | undefined;
}> {
  const { data } = await getUser()

  return {
    userEmail: data?.user?.email,
    userId: data?.user?.id,
  }
}
