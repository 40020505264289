import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Layout from "../../components/Layout"

export default function CancelationGooglePage() {
  const { t } = useTranslation()

  const googleUrl = "https://support.google.com/googleplay/answer/7018481"

  return (
    <Layout>
      <div className="flex h-full flex-col items-center justify-center space-y-8 text-center">
        <ExclamationTriangleIcon className="text-help-center-warning h-20" />

        <p className="text-2xl font-openSansBold">
          {t("pages.helpCenter.activeGoogleSubscription")}
        </p>

        <p className="font-openSans">
          {t("pages.helpCenter.cancelationGoogle.followGoogleInstructions")}
        </p>

        <div className="rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 text-center font-openSansSemiBold">
          <Link className="break-all underline" target="_blank" to={googleUrl}>
            {googleUrl}
          </Link>
        </div>
      </div>
    </Layout>
  )
}
