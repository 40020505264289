import Button from "@components/Button"
import { Form } from "react-router-dom"

interface Props {
  buttonClassName?: string;
  buttonTitle: string;
  couponId: string | undefined;
  currency: string;
  isSubmitting: boolean;
  plan: "weekly" | "yearly";
  priceId: string | undefined;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export default function SubscriptionForm({
  buttonClassName = "",
  buttonTitle,
  couponId,
  currency,
  isSubmitting,
  plan,
  priceId,
  setIsSubmitting,
}: Props) {
  const handleClick = () => {
    setIsSubmitting(true)
  }

  return (
    <Form onSubmit={handleClick} method="post">
      <input type="hidden" name="couponId" value={couponId} />

      <input type="hidden" name="currency" value={currency} />

      <input type="hidden" name="plan" value={plan} />

      <input type="hidden" name="priceId" value={priceId} />

      <Button
        className={buttonClassName}
        disabled={isSubmitting}
        title={buttonTitle}
        type="submit"
      />
    </Form>
  )
}
