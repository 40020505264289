import Dialog from "@components/Dialog"
import localizeDate from "@helpers/localizeDate"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { RevenueCatSubscription } from "../types"

interface Props {
  open: boolean;
  onClose: () => void;
  revenueCatSubscription: RevenueCatSubscription;
}

export default function SubscriptionDialog({
  open,
  onClose,
  revenueCatSubscription,
}: Props) {
  const { t } = useTranslation()

  const expiresDate = new Date(revenueCatSubscription.expires_date)
  const isUnsubscribed = revenueCatSubscription.unsubscribe_detected_at
  const purchaseDate = new Date(revenueCatSubscription.purchase_date)

  const store = () => {
    switch (revenueCatSubscription.store) {
    case null:
    case undefined:
      return t("pages.account.subscription.dialog.unknown").toUpperCase()
    case "stripe":
      return "WEB"
    default:
      return revenueCatSubscription.store.split("_").join(" ").toUpperCase()
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <div>
        <p className="font-openSans">
          {t(`pages.account.subscription.dialog.${isUnsubscribed ? "subscriptionCanceled" : "subscriptionActive"}`)}
        </p>

        <div className="mb-9 mt-7 font-openSans">
          <table className="w-full table-auto">
            <tbody>
              <tr>
                <th className="text-left">
                  {t("pages.account.subscription.dialog.status")}
                </th>

                <td className="py-3">
                  {t(
                    `pages.account.subscription.dialog.${isUnsubscribed ? "statusCanceled" : "statusActive"}`,
                  )}
                </td>
              </tr>

              <tr>
                <th className="text-left">
                  {t("pages.account.subscription.dialog.begins")}
                </th>
                <td className="py-3">{localizeDate(purchaseDate)}</td>
              </tr>

              <tr>
                <th className="text-left">
                  {t("pages.account.subscription.dialog.ends")}
                </th>

                <td className="py-3">{localizeDate(expiresDate)}</td>
              </tr>

              <tr>
                <th className="text-left">
                  {t("pages.account.subscription.dialog.type")}
                </th>

                <td className="py-3">{store()}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="text-center">
          <Link className="font-openSansSemiBold underline" to="mailto:support@filterly.app">
            {t("pages.account.subscription.dialog.needHelpContactUs")}
          </Link>
        </div>
      </div>
    </Dialog>
  )
}
