import Layout from "@components/Layout"
import RadioInputButton from "@components/RadioInputButton"
import { useTranslation } from "react-i18next"
import { Form, useSubmit } from "react-router-dom"
import hairstyleFemale from "../../assets/hairstyle_f.png"
import professionalFemale from "../../assets/pro_f.png"
import retouchFemale from "../../assets/retouch_f.png"
import ageFemale from "../../assets/age_f.png"
import hairstyleMale from "../../assets/hairstyle_m.png"
import professionalMale from "../../assets/pro_m.png"
import retouchMale from "../../assets/retouch_m.png"
import ageMale from "../../assets/age_m.png"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"

export default function TryFirstPage() {
  const submit = useSubmit()
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.tryFirst.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5 pb-8"
          method="post"
        >
          <RadioInputButton
            image={isFemale ? hairstyleFemale : hairstyleMale}
            label={t("pages.onboarding.tryFirst.hairstyle")}
            name="tryFirst"
            onChange={handleChange}
            value="hairstyle"
          />

          <RadioInputButton
            image={isFemale ? ageFemale : ageMale}
            label={t("pages.onboarding.tryFirst.age")}
            name="tryFirst"
            onChange={handleChange}
            value="age"
          />

          <RadioInputButton
            image={isFemale ? professionalFemale : professionalMale}
            label={t("pages.onboarding.tryFirst.professional")}
            name="tryFirst"
            onChange={handleChange}
            value="professional"
          />

          <RadioInputButton
            image={isFemale ? retouchFemale : retouchMale}
            label={t("pages.onboarding.tryFirst.retouch")}
            name="tryFirst"
            onChange={handleChange}
            value="retouch"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.tryFirst.other")}
            name="tryFirst"
            onChange={handleChange}
            value="other"
          />
        </Form>
      </div>
    </Layout>
  )
}
