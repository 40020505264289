import RootOutlet from "@components/RootOutlet"
import ErrorPage from "@pages/ErrorPage"
import { Navigate, Outlet } from "react-router-dom"
import accountRoutes from "./account/accountRoutes"
import checkoutRoutes from "./checkout/checkoutRoutes"
import helpCenterRoutes from "./helpCenter/helpCenterRoutes"
import onboardingRoutes from "./onboarding/onboardingRoutes"
import signUpRoutes from "./signUp/signUpRoutes"

const routes = [
  {
    element: <RootOutlet />,
    errorElement: <ErrorPage />,
    path: "/",
    children: [
      {
        element: <Navigate to="onboarding" replace />,
        index: true,
      },
      {
        element: <Outlet />,
        path: "account",
        children: accountRoutes,
      },
      {
        element: <Outlet />,
        path: "checkout",
        children: checkoutRoutes,
      },
      {
        element: <Outlet />,
        path: "help-center",
        children: helpCenterRoutes,
      },
      {
        element: <Outlet />,
        path: "onboarding",
        children: onboardingRoutes,
      },
      {
        element: <Outlet />,
        path: "sign-up",
        children: signUpRoutes,
      },
    ],
  },
]

export default routes
