import Button from "@components/Button"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLoaderData, useNavigate } from "react-router-dom"
import Layout from "../../components/Layout"
import CancelMultipleSubscriptionsDialog from "../../components/helpCenter/CancelMultipleSubscriptionsDialog"
import { cancelRevenueCatSubscriptions } from "../../helpers/supabase"
import { GetSubscriptionsLoaderData } from "../../loaders/getSubscriptionsLoader"

export type CancelationType = "all" | "web";

export default function CancelationPage() {
  const [displayDialog, setDisplayDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState<CancelationType | null>(
    null,
  )

  const { cancelableSubscriptions, userEmail } =
    useLoaderData() as GetSubscriptionsLoaderData

  const navigate = useNavigate()
  const { t } = useTranslation()

  const hasMultipleActiveSubscriptions = cancelableSubscriptions.length > 1

  const hasNoActiveSubscriptions = cancelableSubscriptions.length === 0

  const cancelSubscriptions = async (cancelationType: CancelationType) => {
    const {
      activeAppStoreSubscriptionsCount,
      activePlayStoreSubscriptionsCount,
      canceledStripeSubscriptionsCount,
    } = await cancelRevenueCatSubscriptions()

    navigate("/help-center/cancelation/completed", {
      replace: true,
      state: {
        activeAppStoreSubscriptionsCount,
        activePlayStoreSubscriptionsCount,
        canceledStripeSubscriptionsCount,
        cancelationType,
      },
    })
  }

  const handleClick = async () => {
    setIsSubmitting("all")

    if (hasMultipleActiveSubscriptions) {
      setDisplayDialog(true)
    } else {
      await cancelSubscriptions("all")
    }

    setIsSubmitting(null)
  }

  const labelClassName = "font-openSansBold"
  const inputClassName = "theme-input"

  return (
    <>
      <Layout>
        <div className="shrink-0">
          <h1 className="font-openSansBold">
            {t("pages.helpCenter.cancelation.heading")}
          </h1>
        </div>

        <div className="flex grow flex-col justify-center space-y-16">
          <form className="space-y-8">
            <div className="space-y-3">
              <label className={labelClassName}>
                {t("pages.helpCenter.shared.emailAddress")}
              </label>

              <input
                className={inputClassName}
                defaultValue={userEmail}
                disabled
                type="email"
              />
            </div>

            <div className="space-y-3">
              <label className={labelClassName}>
                {t("pages.helpCenter.shared.subject")}
              </label>

              <input
                className={inputClassName}
                defaultValue={t("pages.helpCenter.cancelSubscription")}
                disabled
              />
            </div>
          </form>

          {hasNoActiveSubscriptions && (
            <div className="rounded-lg border border-[#E2A2A2] bg-[#E2A2A215] p-5 font-openSansSemiBold text-center">
              <p>{t("pages.helpCenter.shared.noActiveSubscription")}</p>
            </div>
          )}
        </div>

        <div className="shrink-0 font-openSansSemiBold">
          <Button
            disabled={hasNoActiveSubscriptions || !!isSubmitting}
            onClick={handleClick}
            title={t("pages.helpCenter.cancelation.requestCancelation")}
          />
        </div>
      </Layout>

      {displayDialog && (
        <CancelMultipleSubscriptionsDialog
          cancelableSubscriptions={cancelableSubscriptions}
          cancelSubscriptions={cancelSubscriptions}
          isSubmitting={isSubmitting}
          onClose={() => setDisplayDialog(false)}
          open
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </>
  )
}
