import Button from "@components/Button"
import {
  DialogPanel,
  DialogTitle,
  Dialog as HeadlessUiDialog,
  Transition,
  TransitionChild,
} from "@headlessui/react"
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { RefundType } from "@pages/helpCenter/RefundPage"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { RevenueCatSubscription } from "src/types"

interface Props {
  isSubmitting: RefundType | null;
  onClose: () => void;
  open: boolean;
  refundSubscriptions: (refundType: RefundType) => Promise<void>;
  refundableSubscriptions: RevenueCatSubscription[];
  setIsSubmitting: (isSubmitting: RefundType | null) => void;
}

export default function RefundMultipleSubscriptionsDialog({
  isSubmitting,
  onClose,
  open,
  refundSubscriptions,
  refundableSubscriptions,
  setIsSubmitting,
}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleRefundAllSubscriptions = async () => {
    setIsSubmitting("all")
    await refundSubscriptions("all")
    setIsSubmitting(null)
  }

  const handleRefundWebSubscriptions = async () => {
    setIsSubmitting("web")
    await refundSubscriptions("web")
    setIsSubmitting(null)
  }

  const hasAppleSubscription = refundableSubscriptions.some((subscription) =>
    ["app_store", "mac_app_store"].includes(subscription.store),
  )

  const hasGoogleSubscription = refundableSubscriptions.some(
    (subscription) => subscription.store === "play_store",
  )

  return (
    <Transition show={open}>
      <HeadlessUiDialog onClose={onClose} open={open}>
        {/* NOTE: The backdrop, rendered as a fixed sibling to the panel container */}
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div aria-hidden="true" className="fixed inset-0 bg-black/70" />
        </TransitionChild>

        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {/* NOTE: Scrollable dialog, see: https://headlessui.com/react/dialog#scrollable-dialogs */}
          <div className="fixed inset-0 w-screen overflow-y-auto p-4">
            <div className="flex min-h-full items-center justify-center">
              {/* NOTE: The actual dialog panel  */}
              <DialogPanel className="max-w-lg space-y-4 rounded-3xl bg-help-center-white p-8 text-black shadow sm:w-full">
                <DialogTitle as="div" className="text-right">
                  <button
                    className="rounded-full text-[#82899C] hover:opacity-80"
                    onClick={onClose}
                    type="button"
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </DialogTitle>

                <div className="flex h-full flex-col items-center justify-center space-y-8 text-center">
                  <ExclamationTriangleIcon className="h-20 text-help-center-warning" />

                  <div className="space-y-5">
                    <p className="text-2xl font-openSansBold">
                      {t("pages.helpCenter.refund.multipleActiveSubscriptions")}
                    </p>

                    <p className="font-openSans">
                      {t("pages.helpCenter.refund.whichOneToRefund")}
                    </p>

                    <Button
                      disabled={!!isSubmitting}
                      onClick={handleRefundWebSubscriptions}
                      title="Web"
                      className="theme-button-primary-black"
                    />

                    {hasAppleSubscription && (
                      <Button
                        onClick={() => {
                          navigate("/help-center/refund/apple")
                        }}
                        title="Apple"
                        className="theme-button-primary-black"
                      />
                    )}

                    {hasGoogleSubscription && (
                      <Button
                        onClick={() => {
                          navigate("/help-center/refund/google")
                        }}
                        title="Google"
                        className="theme-button-primary-black"
                      />
                    )}

                    <Button
                      disabled={!!isSubmitting}
                      onClick={handleRefundAllSubscriptions}
                      title={t("pages.helpCenter.cancelation.all")}
                      className="theme-button-primary-black"
                    />
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </TransitionChild>
      </HeadlessUiDialog>
    </Transition>
  )
}
