import { redirect } from "react-router-dom"
import { getOrCreateRevenueCatCustomer } from "../helpers/getOrCreateRevenueCatCustomer"
import { getUser } from "../helpers/supabase"
import { RevenueCatCustomer, RevenueCatSubscription } from "../types"

export interface GetSubscriptionsLoaderData {
  cancelableSubscriptions: RevenueCatSubscription[];
  refundableSubscriptions: RevenueCatSubscription[];
  userEmail: string;
}

export default async function getSubscriptionsLoader(): Promise<
  GetSubscriptionsLoaderData | Response
  > {
  const { userId, userEmail } = await getSupabaseUser()

  if (!userId) {
    let url = "/account/sign-in"

    if (location.pathname) {
      url += `?path=${location.pathname}`
    }

    return redirect(url)
  }

  const revenueCatCustomer: RevenueCatCustomer =
    await getOrCreateRevenueCatCustomer(userId)

  const revenueCatSubscriptions =
    revenueCatCustomer?.subscriber?.subscriptions || {}

  const activeRevenueCatSubscriptions = Object.values(
    revenueCatSubscriptions,
  ).filter(
    (revenueCatSubscription: RevenueCatSubscription) =>
      revenueCatSubscription.expires_date > new Date().toISOString(),
  )

  const cancelableSubscriptions = activeRevenueCatSubscriptions.filter(
    (revenueCatSubscription: RevenueCatSubscription) =>
      revenueCatSubscription.unsubscribe_detected_at === null,
  )

  const refundableSubscriptions = activeRevenueCatSubscriptions.filter(
    (revenueCatSubscription: RevenueCatSubscription) =>
      revenueCatSubscription.refunded_at === null,
  )

  return {
    cancelableSubscriptions,
    refundableSubscriptions,
    userEmail: userEmail!,
  }
}

async function getSupabaseUser(): Promise<{
  userEmail: string | undefined;
  userId: string | undefined;
}> {
  const { data } = await getUser()

  return {
    userEmail: data?.user?.email,
    userId: data?.user?.id,
  }
}
