import Input from "@components/Input"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  autoFocus?: boolean;
  invalid?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function PasswordInput({
  autoFocus = false,
  invalid = false,
  name,
  onChange,
}: Props) {
  const [hidePassword, setHidePassword] = useState(true)

  const { t } = useTranslation()

  return (
    <div className="relative">
      <Input
        autoFocus={autoFocus}
        invalid={invalid}
        name={name}
        onChange={onChange}
        placeholder={t("shared.password")}
        type={hidePassword ? "password" : "text"}
      />

      <button
        className="absolute inset-y-0 right-0 flex items-center pr-5"
        onClick={() => setHidePassword(!hidePassword)}
        type="button"
      >
        {hidePassword ? (
          <EyeSlashIcon className="text-custom-gray h-6 w-6" />
        ) : (
          <EyeIcon className="text-custom-gray h-6 w-6" />
        )}
      </button>
    </div>
  )
}
